import React, { useState, useEffect, useContext } from "react";
import { useNavigate, useLocation } from "react-router-dom";
import axios from "axios";
import { formatDate, formatTime } from "../../../constants/helper";
import { AuthContext } from "../../../contexts/AuthContext";
import { tempUrl, useStateContext } from "../../../contexts/ContextProvider";
import { ShowTableJualCustomerPerSurveyor } from "../../../components/ShowTable";
import { FetchErrorHandling } from "../../../components/FetchErrorHandling";
import { SearchBar, Loader, ButtonModifierJual } from "../../../components";
import {
  Box,
  TextField,
  Typography,
  Divider,
  Pagination,
  Button,
  ButtonGroup,
  Paper,
  FormControl,
  FormLabel,
  RadioGroup,
  FormControlLabel,
  Radio,
  Card,
  CardHeader,
  CardActionArea,
  CardMedia,
  InputAdornment,
  Link,
  Autocomplete,
} from "@mui/material";
import jsPDF from "jspdf";
import "jspdf-autotable";
import * as XLSX from "xlsx";
import SearchIcon from "@mui/icons-material/Search";
import DownloadIcon from "@mui/icons-material/Download";
import PrintIcon from "@mui/icons-material/Print";
import angkaTerbilang from "@develoka/angka-terbilang-js";
import { Col, Row, Form } from "react-bootstrap";
import Carousel from "react-elastic-carousel";

const TampilCustomerPerSurveyor = () => {
  const { user, setting, dispatch } = useContext(AuthContext);
  const location = useLocation();
  const id = location.pathname.split("/")[2];
  const { screenSize } = useStateContext();
  const textRight = {
    textAlign: screenSize >= 650 && "right",
  };
  const textRightSmall = {
    textAlign: screenSize >= 650 && "right",
    fontSize: "13px",
  };
  const [isFetchError, setIsFetchError] = useState(false);
  // Data Register/Pembeli
  const [noRegister, setNoRegister] = useState("");
  const [namaRegister, setNamaRegister] = useState("");
  const [almRegister, setAlmRegister] = useState("");
  const [almKantor, setAlmKantor] = useState("");
  const [tlpRegister, setTlpRegister] = useState("");
  const [noKtpRegister, setNoKtpRegister] = useState("");
  const [noKKRegister, setNoKKRegister] = useState("");
  const [namaPjmRegister, setNamaPjmRegister] = useState("");
  const [noKtpPjmRegister, setNoKtpPjmRegister] = useState("");
  const [linkMapsRegister, setLinkMapsRegister] = useState("");
  const [linkMapsRegister2, setLinkMapsRegister2] = useState("");
  const [linkMapsRegister3, setLinkMapsRegister3] = useState("");
  const [email, setEmail] = useState("");
  const [namaRefRegister, setNamaRefRegister] = useState("");
  const [almRefRegister, setAlmRefRegister] = useState("");
  const [tlpRefRegister, setTlpRefRegister] = useState("");
  const [kodeMarketing, setKodeMarketing] = useState("");
  const [kodeSurveyor, setKodeSurveyor] = useState("");
  const [kodeSurveyorAsli, setKodeSurveyorAsli] = useState("");
  const [kodePekerjaan, setKodePekerjaan] = useState("");
  const [ketPekerjaan, setKetPekerjaan] = useState("");
  const [kodeKecamatan, setKodeKecamatan] = useState("");
  const [kodeLeasing, setKodeLeasing] = useState("");

  // Data Motor -> Dari Stok
  const [noRangka, setNoRangka] = useState("");
  const [noMesin, setNoMesin] = useState("");
  const [nopol, setNopol] = useState("");
  const [tipe, setTipe] = useState("");
  const [namaWarna, setNamaWarna] = useState("");
  const [tahun, setTahun] = useState("");
  const [namaStnk, setNamaStnk] = useState("");
  const [noBpkb, setNoBpkb] = useState("");

  // Data Penjualan -> dari input
  const [hargaTunai, setHargaTunai] = useState("");
  const [uangMuka, setUangMuka] = useState("");
  const [sisaPiutang, setSisaPiutang] = useState("");
  const [angPerBulan, setAngPerBulan] = useState("");
  const [tenor, setTenor] = useState("");
  const [sisaBulan, setSisaBulan] = useState("");
  const [bunga, setBunga] = useState("");
  const [jumlahPiutang, setJumlahPiutang] = useState("");
  const [angModal, setAngModal] = useState("");
  const [angBunga, setAngBunga] = useState("");
  const [noJual, setNoJual] = useState("");
  const [noKwitansi, setNoKwitansi] = useState("");
  const [tglJual, setTglJual] = useState("");
  const [tglJualDate, setTglJualDate] = useState();
  const [jenisJual, setJenisJual] = useState("");
  const [leasing, setLeasing] = useState("");
  const [kodeCOA, setKodeCOA] = useState("");
  const [tglAng, setTglAng] = useState("");
  const [tglAngFormatted, setTglAngFormatted] = useState("");
  const [tglAngAkhir, setTglAngAkhir] = useState("");
  const [tglAngAkhirFormatted, setTglAngAkhirFormatted] = useState("");
  const [kodeAngsuran, setKodeAngsuran] = useState("");
  const [gambar, setGambar] = useState([]);
  const [isPost, setIsPost] = useState("");

  const [searchTerm, setSearchTerm] = useState("");
  const [jualsData, setJualsData] = useState([]);

  const [value, setValue] = useState("Semua");
  const [kodeSurveyorFilter, setKodeSurveyorFilter] = useState("");
  const [surveyors, setSurveyors] = useState([]);

  const [pilihCetak, setPilihCetak] = useState("daftarJual");
  const navigate = useNavigate();
  let isJualExist = id;
  let isEditable = user.tipeUser !== "ADM" && isPost === false;

  const columns = [
    { title: "No. Jual", field: "noJual" },
    { title: "Tanggal Jual", field: "tanggalJual" },
    { title: "Nama Register", field: "namaRegister" },
    { title: "Kode Leasing", field: "kodeLeasing" },
    { title: "Tipe", field: "tipe" },
  ];

  const [loading, setLoading] = useState(false);
  let [page, setPage] = useState(0);
  const [limit, setLimit] = useState(20);
  const [pages, setPages] = useState(0);
  const [rows, setRows] = useState(0);
  const [query, setQuery] = useState("");

  const handleChangeFilter = (event) => {
    setValue(event.target.value);
  };

  const handleChange = (e, p) => {
    setPage(p - 1);
  };

  const searchData = (e) => {
    e.preventDefault();
    setPage(0);
    setSearchTerm(query);
  };

  const breakPoints = [
    { width: 1, itemsToShow: 1 },
    { width: 550, itemsToShow: 3 },
    { width: 768, itemsToShow: 4 },
    { width: 1200, itemsToShow: 5 },
  ];

  const surveyorOptions = surveyors.map((surveyor) => ({
    label: `${surveyor.kodeSurveyor} - ${surveyor.namaSurveyor}`,
  }));

  useEffect(() => {
    id && getJualById();
  }, [id]);

  useEffect(() => {
    getSurveyor();
    getJualsData();
  }, [page, searchTerm, id]);

  const getSurveyor = async () => {
    setLoading(true);
    const allSurveyors = await axios.post(`${tempUrl}/surveyors`, {
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    setSurveyors(allSurveyors.data);
    setLoading(false);
  };

  const getJualsData = async () => {
    setLoading(true);
    try {
      let allJuals;
      if (user.tipeUser === "EKS") {
        allJuals = await axios.post(
          `${tempUrl}/jualsCustomerPerSurveyorKodeKolektorPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            status: value,
            kodeSurveyor: kodeSurveyorFilter.split(" -", 1)[0],
            kodeKolektor: user.kodeKolektor,
            dariTgl: user.periode.periodeAwal,
            sampaiTgl: user.periode.periodeAkhir,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setQuery(searchTerm);
        setJualsData(allJuals.data.juals);
        setPage(allJuals.data.page);
        setPages(allJuals.data.totalPage);
        setRows(allJuals.data.totalRows);
      } else if (user.tipeUser === "SUR") {
        allJuals = await axios.post(
          `${tempUrl}/jualsCustomerPerSurveyorKodeSurveyorPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            status: value,
            kodeSurveyor: user.kodeSurveyor,
            dariTgl: user.periode.periodeAwal,
            sampaiTgl: user.periode.periodeAkhir,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setQuery(searchTerm);
        setJualsData(allJuals.data.juals);
        setPage(allJuals.data.page);
        setPages(allJuals.data.totalPage);
        setRows(allJuals.data.totalRows);
      } else if (user.tipeUser === "MAR") {
        allJuals = await axios.post(
          `${tempUrl}/jualsCustomerPerSurveyorKodeMarketingPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            status: value,
            kodeSurveyor: kodeSurveyorFilter.split(" -", 1)[0],
            kodeMarketing: user.kodeMarketing,
            dariTgl: user.periode.periodeAwal,
            sampaiTgl: user.periode.periodeAkhir,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setQuery(searchTerm);
        setJualsData(allJuals.data.juals);
        setPage(allJuals.data.page);
        setPages(allJuals.data.totalPage);
        setRows(allJuals.data.totalRows);
      } else {
        allJuals = await axios.post(
          `${tempUrl}/jualsCustomerPerSurveyorPagination?search_query=${searchTerm}&page=${page}&limit=${limit}`,
          {
            status: value,
            kodeSurveyor: kodeSurveyorFilter.split(" -", 1)[0],
            dariTgl: user.periode.periodeAwal,
            sampaiTgl: user.periode.periodeAkhir,
            id: user._id,
            token: user.token,
            kodeCabang: user.cabang._id,
          }
        );
        setQuery(searchTerm);
        setJualsData(allJuals.data.juals);
        setPage(allJuals.data.page);
        setPages(allJuals.data.totalPage);
        setRows(allJuals.data.totalRows);
      }
    } catch (error) {
      console.log(error);
      if (error.response.status == 401) {
        dispatch({ type: "LOGOUT" });
        navigate("/");
      }
      setIsFetchError(true);
    }
    setLoading(false);
  };

  const getJualById = async () => {
    setLoading(true);
    if (id) {
      const response = await axios.post(`${tempUrl}/juals/${id}`, {
        id: user._id,
        token: user.token,
      });
      // Data Register/Pembeli
      setNoRegister(response.data.noRegister);
      setNamaRegister(response.data.namaRegister);
      setAlmRegister(response.data.almRegister);
      setAlmKantor(response.data.almKantor);
      setTlpRegister(response.data.tlpRegister);
      setNoKtpRegister(response.data.noKtpRegister);
      setNoKKRegister(response.data.noKKRegister);
      setNamaPjmRegister(response.data.namaPjmRegister);
      setNoKtpPjmRegister(response.data.noKtpPjmRegister);
      setLinkMapsRegister(response.data.linkMapsRegister);
      setLinkMapsRegister2(response.data.linkMapsRegister2);
      setLinkMapsRegister3(response.data.linkMapsRegister3);
      setEmail(response.data.email);
      setNamaRefRegister(response.data.namaRefRegister);
      setAlmRefRegister(response.data.almRefRegister);
      setTlpRefRegister(response.data.tlpRefRegister);
      setKodeMarketing(response.data.kodeMarketing);
      setKodeSurveyor(response.data.kodeSurveyor);
      setKodeSurveyorAsli(response.data.kodeSurveyorAsli);
      setKodePekerjaan(response.data.kodePekerjaan);
      setKetPekerjaan(response.data.ketPekerjaan);
      setKodeKecamatan(response.data.kodeKecamatan);
      setKodeLeasing(response.data.kodeLeasing);
      setKodeCOA(response.data.COA);

      // Data Motor -> Dari Stok
      setNoRangka(response.data.noRangka);
      setNoMesin(response.data.noMesin);
      setNopol(response.data.nopol);
      setTipe(response.data.tipe);
      setNamaWarna(response.data.namaWarna);
      setTahun(response.data.tahun);
      setNamaStnk(response.data.namaStnk);
      setNoBpkb(response.data.noBpkb);

      // Data Penjualan -> dari input
      setHargaTunai(response.data.hargaTunai);
      setUangMuka(response.data.uangMuka);
      setSisaPiutang(response.data.sisaPiutang);
      setAngPerBulan(response.data.angPerBulan);
      setTenor(response.data.tenor);
      setSisaBulan(response.data.sisaBulan);
      setBunga(response.data.bunga);
      setJumlahPiutang(response.data.jumlahPiutang);
      setAngModal(response.data.angModal);
      setAngBunga(response.data.angBunga);
      setNoJual(response.data.noJual);
      setNoKwitansi(response.data.noKwitansi);
      setTglJual(formatDate(response.data.tanggalJual));
      setTglJualDate(new Date(response.data.tanggalJual));
      setJenisJual(response.data.jenisJual);
      setLeasing(response.data.leasing);
      setTglAng(response.data.tglAng);
      setTglAngFormatted(formatDate(response.data.tglAng));
      setTglAngAkhir(response.data.tglAngAkhir);
      setTglAngAkhirFormatted(formatDate(response.data.tglAngAkhir));
      setKodeAngsuran(response.data.kodeAngsuran);
      setGambar(response.data.gambar);
      setIsPost(response.data.isPost);
    }
    setLoading(false);
  };

  const pilihCetakPdf = () => {
    if (pilihCetak === "daftarJual") {
      downloadPdfDaftarJual();
    } else if (pilihCetak === "kwitansiUmKredit") {
      downloadPdfCetakKwitansiKredit();
    } else if (pilihCetak === "suratPerjanjian") {
      downloadPdfSuratPerjanjian();
    } else if (pilihCetak === "kwitansiUmTunai") {
      downloadPdfCetakKwitansiTunai();
    } else if (pilihCetak === "suratPengantar") {
      downloadPdfSuratPengantar();
    } else if (pilihCetak === "tandaTerimaBPKB") {
      downloadPdfTandaTerimaBPKB();
    }
  };

  const downloadPdfDaftarJual = async () => {
    setLoading(true);
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);

    const allJualsForTable = await axios.post(`${tempUrl}/jualsForTable`, {
      dariTgl: user.periode.periodeAwal,
      sampaiTgl: user.periode.periodeAkhir,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    const doc = new jsPDF();
    doc.setFontSize(12);
    doc.text(`${setting.namaPerusahaan} - ${setting.kotaPerusahaan}`, 15, 10);
    doc.text(`${setting.lokasiPerusahaan}`, 15, 15);
    doc.setFontSize(16);
    doc.text(`Daftar Jual`, 90, 30);
    doc.setFontSize(10);
    doc.text(`Periode: ${user.periode.namaPeriode}`, 15, 35);
    doc.text(
      `Dicetak Oleh: ${user.username} | Tanggal : ${current_date} | Jam : ${current_time}`,
      15,
      290
    );
    doc.setFontSize(12);
    doc.autoTable({
      startY: doc.pageCount > 1 ? doc.autoTableEndPosY() + 20 : 45,
      columns: columns.map((col) => ({ ...col, dataKey: col.field })),
      body: allJualsForTable.data,
      headStyles: {
        fillColor: [117, 117, 117],
        color: [0, 0, 0],
      },
    });
    window.open(URL.createObjectURL(doc.output("blob")));
    setLoading(false);
  };

  const downloadPdfCetakKwitansiTunai = async () => {
    const tempStok = await axios.post(`${tempUrl}/daftarStoksByNorang`, {
      noRangka,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });

    var date = new Date();
    var current_date = formatDate(date);
    let tempX1 = 54;
    let tempY = 42;
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(`${noKwitansi}`, 165, 18);
    doc.text(`${namaRegister}`, tempX1, tempY);
    tempY += 6;
    doc.text(`${almRegister.slice(0, 45)}`, tempX1, tempY);
    tempY += 13;
    doc.setFont(undefined, "bold");
    doc.text(`${angkaTerbilang(uangMuka)} rupiah`, tempX1, tempY);
    doc.setFont(undefined, "normal");
    tempY += 10;
    doc.text(
      `Pembayaran 1 (satu) unit sepeda motor ${tempStok.data.merk}, Tipe : ${tipe}`,
      tempX1,
      tempY
    );
    tempY += 6;
    doc.text(
      `No.Rangka : ${noRangka}.   Nomor Mesin : ${noMesin}`,
      tempX1,
      tempY
    );
    tempY += 6;
    if (nopol.length > 0) {
      // Bekas
      doc.text(
        `Keadaan Bekas pakai, Tahun ${tahun},    Warna : ${namaWarna}`,
        tempX1,
        tempY
      );
    } else {
      // Baru
      doc.text(
        `Keadaan 100% BARU, Tahun ${tahun} Warna : ${namaWarna}`,
        tempX1,
        tempY
      );
    }
    tempY += 6;
    doc.text(`${nopol}`, tempX1, tempY);
    tempY += 34;
    doc.setFont(undefined, "bold");
    doc.text(`${uangMuka.toLocaleString("en-US")}`, tempX1 + 10, tempY);
    doc.setFont(undefined, "normal");
    doc.text(`${current_date}`, 140, tempY);
    tempY += 35;
    doc.text(`${namaRegister.slice(0, 30)}`, tempX1 - 8, tempY);
    doc.text(`( ${user.username} )`, 140, tempY);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfCetakKwitansiKredit = async () => {
    const tempStok = await axios.post(`${tempUrl}/daftarStoksByNorang`, {
      noRangka,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    let makeTglAng1 = new Date(tglAng);
    let makeTglAngAkhir = new Date(tglAngAkhir);
    let tempTglAng1 = formatDate(makeTglAng1);
    let tempTglAngAkhir = formatDate(makeTglAngAkhir);
    let tempX1 = 55;
    let tempY = 40;
    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(10);
    doc.text(`${noKwitansi}`, 165, 22);
    doc.text(`${namaRegister}`, tempX1, tempY);
    tempY += 6;
    doc.text(`${almRegister.slice(0, 45)}`, tempX1, tempY);
    tempY += 11;
    doc.setFont(undefined, "bold");
    doc.text(`${angkaTerbilang(uangMuka)} rupiah`, tempX1, tempY);
    doc.setFont(undefined, "normal");
    tempY += 11;
    doc.text(
      `UANG MUKA 1 (satu) unit sepeda motor ${tempStok.data.merk} Tipe : ${tipe}`,
      tempX1,
      tempY
    );
    tempY += 5;
    doc.text(
      `No.Rangka : ${noRangka}.   Nomor Mesin : ${noMesin}`,
      tempX1,
      tempY
    );
    tempY += 5;
    if (nopol.length > 0) {
      // Bekas
      doc.text(`Tahun ${tahun},    Warna : ${namaWarna}`, tempX1, tempY);
    } else {
      // Baru
      doc.text(
        `Keadaan 100% BARU, Tahun ${tahun} Warna : ${namaWarna}`,
        tempX1,
        tempY
      );
    }
    tempY += 6;
    doc.setFont(undefined, "bold");
    doc.text(`CATATAN : `, tempX1, tempY);
    doc.setFont(undefined, "normal");
    tempY += 5;
    doc.text(
      `SEWA BELI ${tenor} Bulan. Pembayaran Uang Muka Rp. ${uangMuka.toLocaleString(
        "en-US"
      )} dan angsuran perbulan`,
      tempX1,
      tempY
    );
    tempY += 5;
    doc.text(
      `Rp. ${angPerBulan.toLocaleString(
        "en-US"
      )} X ${tenor}. Terhitung angsuran Ke-1 (satu) Mulai TGL.${tempTglAng1} s/d`,
      tempX1,
      tempY
    );
    tempY += 5;
    doc.text(
      `Angsuran Ke-${tenor} (${angkaTerbilang(
        tenor
      )} bulan) Jatuh tempo pembayarannya TGL.${tempTglAngAkhir}`,
      tempX1,
      tempY
    );
    tempY += 5;
    doc.text(`Kontrak No. ${noJual}`, tempX1, tempY);
    tempY += 13;
    doc.setFontSize(12);
    doc.setFont(undefined, "bold");
    doc.text(`${uangMuka.toLocaleString("en-US")}`, tempX1 + 10, tempY);
    doc.setFont(undefined, "normal");
    doc.text(`${current_date}`, 140, tempY);
    doc.setFontSize(10);
    tempY += 35;
    doc.text(`${namaRegister.slice(0, 30)}`, tempX1, tempY);
    doc.text(`( ${user.username} )`, 140, tempY);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfSuratPerjanjian = async () => {
    const tempStok = await axios.post(`${tempUrl}/daftarStoksByNorang`, {
      noRangka,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    const tempRegister = await axios.post(`${tempUrl}/registersByNo`, {
      noRegister,
      id: user._id,
      token: user.token,
      kodeCabang: user.cabang._id,
    });
    let makeTglAng1 = new Date(tglAng);
    let makeTglAngAkhir = new Date(tglAngAkhir);
    var myDays = [
      "Minggu",
      "Senin",
      "Selasa",
      "Rabu",
      "Kamis",
      "Jumat",
      "Sabtu",
    ];
    let thisDayNumber = makeTglAng1.getDate();
    let thisDay = myDays[tglJualDate.getDay()];
    let findMonth = (monthNumber) => {
      if (monthNumber === 1) {
        return "JANUARI";
      } else if (monthNumber === 2) {
        return "FEBRUARI";
      } else if (monthNumber === 3) {
        return "MARET";
      } else if (monthNumber === 4) {
        return "APRIL";
      } else if (monthNumber === 5) {
        return "MEI";
      } else if (monthNumber === 6) {
        return "JUNI";
      } else if (monthNumber === 7) {
        return "JULI";
      } else if (monthNumber === 8) {
        return "AGUSTUS";
      } else if (monthNumber === 9) {
        return "SEPTEMBER";
      } else if (monthNumber === 10) {
        return "OKTOBER";
      } else if (monthNumber === 11) {
        return "NOVEMBER";
      } else if (monthNumber === 12) {
        return "DESEMBER";
      }
    };
    let monthYearTglAng1 =
      findMonth(makeTglAng1.getMonth() + 1) + " " + makeTglAng1.getFullYear();
    let monthYearTglAngAkhir =
      findMonth(makeTglAngAkhir.getMonth() + 1) +
      " " +
      makeTglAngAkhir.getFullYear();
    var date = new Date(tglJualDate);
    let tempDateName = findMonth(date.getMonth() + 1);
    var current_date =
      date.getDate() +
      " " +
      findMonth(date.getMonth() + 1) +
      " " +
      date.getFullYear();
    var current_time = formatTime(date);
    let tempY = 40;
    const doc = new jsPDF();
    doc.setFontSize(8);
    doc.text(`${noJual}`, 90, tempY);
    tempY += 5;
    doc.text(`${current_date}`, 90, tempY);
    tempY += 10;
    doc.text(`${thisDay}`, 40, tempY);
    doc.text(`${date.getDate()}`, 85, tempY);
    doc.text(`${tempDateName}`, 114, tempY);
    doc.text(`${date.getFullYear()}`, 150, tempY);
    tempY += 9;
    doc.text(`${setting.namaPemilik}`, 45, tempY);
    tempY += 5;
    doc.text(`${setting.pekerjaanPemilik}`, 45, tempY);
    tempY += 5;
    doc.text(`${setting.alamatPemilik}`, 45, tempY);
    tempY += 13;
    doc.text(`${namaRegister}`, 45, tempY);
    tempY += 5;
    doc.text(`${tempRegister.data.pekerjaanId.namaPekerjaan}`, 45, tempY);
    tempY += 5;
    doc.text(`${almRegister.slice(0, 40)}`, 45, tempY);
    tempY += 5;
    doc.text(`${noKtpRegister}`, 45, tempY);
    tempY += 14;
    doc.text(`${tempRegister.data.namaPjmRegister}`, 45, tempY);
    tempY += 5;
    doc.text(
      `${tempRegister.data.pekerjaanPenjaminId.namaPekerjaan}`,
      45,
      tempY
    );
    tempY += 5;
    doc.text(`${tempRegister.data.almPjmRegister.slice(0, 40)}`, 45, tempY);
    tempY += 5;
    doc.text(`${tempRegister.data.noKtpPjmRegister}`, 45, tempY);
    tempY += 26;
    doc.text(`${current_date}`, 65, tempY);
    tempY += 5;
    doc.text(`${tempStok.data.merk}`, 130, tempY);
    tempY += 24;
    doc.text(`${tempStok.data.merk}/${tipe}`, 24, tempY + 4);
    doc.text(`${tahun}`, 55, tempY);
    doc.text(`${namaWarna}`, 84, tempY);
    doc.text(`${noRangka}`, 124, tempY);
    doc.text(`${noMesin}`, 175, tempY);
    tempY += 24;
    doc.text(`${tempStok.data.merk}`, 135, tempY);
    tempY += 5;
    doc.text(
      `${(uangMuka + jumlahPiutang).toLocaleString("en-US")} ( ${angkaTerbilang(
        uangMuka + jumlahPiutang
      )} rupiahs )`,
      180,
      tempY,
      {
        align: "right",
      }
    );
    tempY += 33;
    doc.text(
      `${uangMuka.toLocaleString("en-US")}                  ${angkaTerbilang(
        uangMuka
      )} rupiah`,
      100,
      tempY
    );
    tempY += 5;
    doc.text(`${jumlahPiutang.toLocaleString("en-US")}`, 100, tempY);
    tempY += 5;
    doc.text(`${thisDayNumber}`, 22, tempY);
    doc.text(`${angkaTerbilang(thisDayNumber)}`, 35, tempY);
    doc.text(`${tenor}`, 125, tempY);
    doc.text(`${angkaTerbilang(tenor)}`, 160, tempY);
    tempY += 4.5;
    doc.text(`${monthYearTglAng1}`, 80, tempY);
    doc.text(`${monthYearTglAngAkhir}`, 160, tempY);
    tempY += 5;
    doc.text(`${angPerBulan.toLocaleString("en-US")}`, 110, tempY);
    tempY += 4.5;
    doc.text(`# ${angkaTerbilang(angPerBulan)} rupiah #`, 25, tempY);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfTandaTerimaBPKB = async () => {
    let tempY = 49;
    let tempX = 70;
    let tempYSpace = 7;

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFontSize(9);
    doc.text(`${namaRegister}`, tempX, tempY);
    tempY += tempYSpace;
    doc.text(`${noKtpRegister}`, tempX, tempY);
    tempY += tempYSpace;
    doc.text(`${almRegister.slice(0, 40)}`, tempX, tempY);
    tempY += 13;
    doc.text(`${tipe} / ${tahun}`, tempX, tempY);
    tempY += tempYSpace;
    doc.text(`${nopol}`, tempX, tempY);
    tempY += tempYSpace;
    doc.text(`${noRangka}`, tempX, tempY);
    tempY += tempYSpace;
    doc.text(`${noBpkb}`, tempX, tempY);
    tempY += tempYSpace - 2;
    doc.text(`${tlpRegister}`, tempX, tempY);
    tempY += 26;
    doc.text(`${current_date}`, 150, tempY);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadPdfSuratPengantar = async () => {
    let tempY = 54;
    let tempX1 = 20;
    let tempX2 = 30;
    let tempX3 = 40;
    let tempX4 = 70;
    let tempX5 = 80;
    let tempX6 = 160;
    let tempYSpace = 6;
    let tempYSpaceParagraph = 15;

    var date = new Date();
    var current_date = formatDate(date);
    var current_time = formatTime(date);
    const doc = new jsPDF();
    doc.setFont(undefined, "bold");
    doc.setFontSize(10);
    doc.text("SURAT KETERANGAN", tempX5 + 8, tempY);
    const textWidthTitle = doc.getTextWidth("SURAT KETERANGAN");
    doc.line(tempX5 + 8, tempY + 1, tempX5 + textWidthTitle + 8, tempY + 1);

    doc.setFont(undefined, "normal");
    tempY += tempYSpace;
    doc.setFontSize(8);
    doc.text(`No. ${noJual}`, tempX5 + 8, tempY);
    tempY += tempYSpace;
    doc.text(`Yang bertanda tangan di bawah ini :`, tempX2, tempY);
    tempY += tempYSpace;
    doc.text(`Nama`, tempX3, tempY);
    doc.text(`: ${setting.namaPemilik}`, tempX4, tempY);
    tempY += tempYSpace;
    doc.text(`Alamat`, tempX3, tempY);
    doc.text(`: ${setting.lokasiPerusahaan}`, tempX4, tempY);
    tempY += tempYSpaceParagraph;
    doc.text(
      `Menerangkan bahwa BPKB dengan spesifikasi motor sebagai berikut :`,
      tempX2,
      tempY
    );
    tempY += tempYSpace;
    doc.text(`No. Polisi`, tempX3, tempY);
    doc.text(`: ${nopol}`, tempX4, tempY);
    tempY += tempYSpace;
    doc.text(`Warna / Tahun`, tempX3, tempY);
    doc.text(`: ${namaWarna} / ${tahun}`, tempX4, tempY);
    tempY += tempYSpace;
    doc.text(`No. Rangka`, tempX3, tempY);
    doc.text(`: ${noRangka}`, tempX4, tempY);
    tempY += tempYSpace;
    doc.text(`No. Mesin`, tempX3, tempY);
    doc.text(`: ${noMesin}`, tempX4, tempY);
    tempY += tempYSpace;
    doc.text(`No. BPKB`, tempX3, tempY);
    doc.text(`: ${noBpkb}`, tempX4, tempY);
    tempY += tempYSpace;
    doc.text(`Nama STNK`, tempX3, tempY);
    doc.text(`: ${namaStnk}`, tempX4, tempY);
    tempY += tempYSpaceParagraph;
    doc.text(
      `Bahwa BPKB tersebut saat ini benar berada di kantor ${user.cabang.namaCabang} sebagai syarat sewa beli.`,
      tempX1,
      tempY
    );
    tempY += tempYSpace;
    doc.text(
      `Demikian surat ini kami buat untuk dapat dipergunakan sebagaimana mestinya.`,
      tempX1,
      tempY
    );
    tempY += tempYSpace;
    doc.text(`${setting.lokasiSP}, ${current_date}`, tempX6, tempY);
    tempY += tempYSpace;
    doc.text(`HORMAT KAMI`, tempX6 + 5, tempY);
    doc.setFont(undefined, "bold");
    tempY += tempYSpaceParagraph + 10;
    let tempX7 = 175;
    doc.text(`${setting.namaPemilik}`, tempX7, tempY, {
      align: "center",
    });
    const textWidth = doc.getTextWidth(setting.namaPemilik);
    doc.line(
      tempX7 - textWidth + textWidth / 2,
      tempY + 1,
      tempX7 + textWidth - textWidth / 2,
      tempY + 1
    );

    tempY += tempYSpace;
    doc.text(`MANAGER`, tempX6 + 8, tempY);
    window.open(URL.createObjectURL(doc.output("blob")));
  };

  const downloadExcel = () => {
    const workSheet = XLSX.utils.json_to_sheet(jualsData);
    const workBook = XLSX.utils.book_new();
    XLSX.utils.book_append_sheet(workBook, workSheet, `Jual`);
    // Buffer
    let buf = XLSX.write(workBook, { bookType: "xlsx", type: "buffer" });
    // Binary String
    XLSX.write(workBook, { bookType: "xlsx", type: "binary" });
    // Download
    XLSX.writeFile(workBook, `daftarJual.xlsx`);
  };

  if (loading) {
    return <Loader />;
  }

  if (isFetchError) {
    return <FetchErrorHandling />;
  }

  return (
    <Box>
      <Typography color="#757575">Penjualan</Typography>
      <Typography variant="h4" sx={subTitleText}>
        Customer Per Surveyor
      </Typography>
      <Box sx={showDataContainer}>
        <Box sx={showDataWrapper}>
          <FormControl sx={{ marginTop: 1 }}>
            <FormLabel id="demo-controlled-radio-buttons-group">
              Filter
            </FormLabel>
            <RadioGroup
              row
              aria-labelledby="demo-controlled-radio-buttons-group"
              name="controlled-radio-buttons-group"
              defaultValue="Semua"
              value={value}
              onChange={handleChangeFilter}
            >
              <FormControlLabel
                value="Aktif Saja"
                control={<Radio />}
                label="Aktif Saja"
              />
              <FormControlLabel
                value="Lunas"
                control={<Radio />}
                label="Lunas"
              />
              <FormControlLabel
                value="Penarikan"
                control={<Radio />}
                label="Penarikan"
              />
              <FormControlLabel
                value="Semua"
                control={<Radio />}
                label="Semua"
              />
            </RadioGroup>
          </FormControl>
        </Box>
      </Box>
      <Box sx={showDataContainer}>
        <Box sx={showDataWrapper}>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Kode Surveyor :
                </Form.Label>
                <Col sm="8">
                  <Autocomplete
                    size="small"
                    disablePortal
                    id="combo-box-demo"
                    disabled={user.tipeUser === "SUR" ? true : false}
                    options={surveyorOptions}
                    renderInput={(params) => (
                      <TextField
                        size="small"
                        placeholder="SEMUA SURVEYOR"
                        {...params}
                      />
                    )}
                    onChange={(e, value) => {
                      let tempValue;
                      if (value === null) {
                        console.log("Value null");
                        tempValue = "";
                      } else {
                        tempValue = value.label;
                      }
                      setKodeSurveyorFilter(tempValue);
                    }}
                    value={kodeSurveyorFilter}
                  />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Form.Group
                as={Row}
                className="mb-3"
                controlId="formPlaintextPassword"
              >
                <Form.Label column sm="4" style={textRight}>
                  Total Konsumen :
                </Form.Label>
                <Col sm="8">
                  <Form.Control value={rows.toLocaleString("en-US")} disabled />
                </Col>
              </Form.Group>
            </Col>
          </Row>
          <Row>
            <Col sm={6}>
              <Button
                variant="contained"
                startIcon={<SearchIcon />}
                onClick={() => {
                  console.log("In");
                  getJualsData();
                }}
              >
                CARI
              </Button>
            </Col>
          </Row>
          <Box sx={[showDataWrapper, secondWrapper]}></Box>
        </Box>
      </Box>

      <Box sx={downloadButtons}>
        <ButtonGroup variant="outlined" color="secondary">
          <Button startIcon={<PrintIcon />} onClick={() => pilihCetakPdf()}>
            CETAK
          </Button>
          <Button startIcon={<DownloadIcon />} onClick={() => downloadExcel()}>
            EXCEL
          </Button>
        </ButtonGroup>
      </Box>

      <Divider sx={dividerStyle} />

      {isJualExist && gambar.length !== 0 && (
        <Carousel breakPoints={breakPoints} sx={carouselStyle}>
          {gambar.length !== 0 &&
            gambar.map((img, i) => (
              <Card sx={carouselCard} elevation={10}>
                <CardActionArea>
                  <CardHeader title={`Gambar`} subheader={`No. ${i + 1}`} />
                  <CardMedia
                    component="img"
                    height="100%"
                    src={img}
                    alt={namaRegister}
                    sx={oldImageCardMedia}
                    onClick={() => {
                      window.open(img, "_blank", "noopener,noreferrer");
                    }}
                  />
                </CardActionArea>
              </Card>
            ))}
        </Carousel>
      )}

      <Divider sx={dividerStyle} />
      {isJualExist && (
        <>
          <Box sx={contentContainer}>
            {/* Data Penjualan */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                DATA PENJUALAN
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Jual :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noJual} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Kwitansi :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKwitansi} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tanggal Jual :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tglJual} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Jenis Jual :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={jenisJual} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          COA :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeCOA.kodeCOA} - ${kodeCOA.namaCOA}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>

            {/* Data Customer */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                DATA CUSTOMER
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nama Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Alamat Rumah :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={almRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Alamat Kantor :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={almKantor} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Telepon Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tlpRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. KTP Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKtpRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. KK Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKKRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Nama Penjamin Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaPjmRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          No. KTP Penjamin Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noKtpPjmRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Link Maps Register :
                        </Form.Label>
                        <Col sm="8">
                          <TextField
                            disabled
                            defaultValue={linkMapsRegister}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Link href={linkMapsRegister}>Buka Link</Link>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Link Maps Register 2 :
                        </Form.Label>
                        <Col sm="8">
                          <TextField
                            disabled
                            defaultValue={linkMapsRegister2}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Link href={linkMapsRegister2}>
                                    Buka Link
                                  </Link>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Link Maps Register 3 :
                        </Form.Label>
                        <Col sm="8">
                          <TextField
                            disabled
                            defaultValue={linkMapsRegister3}
                            variant="outlined"
                            size="small"
                            InputProps={{
                              endAdornment: (
                                <InputAdornment position="end">
                                  <Link href={linkMapsRegister3}>
                                    Buka Link
                                  </Link>
                                </InputAdornment>
                              ),
                            }}
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Nama Ref. Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaRefRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Alamat Ref. Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={almRefRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Telepon Ref. Register :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tlpRefRegister} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Marketing :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeMarketing.kodeMarketing} - ${kodeMarketing.namaMarketing}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Surveyor :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeSurveyor.kodeSurveyor} - ${kodeSurveyor.namaSurveyor}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Surveyor Asli :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeSurveyorAsli.kodeSurveyor} - ${kodeSurveyorAsli.namaSurveyor}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Pekerjaan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodePekerjaan.kodePekerjaan} - ${kodePekerjaan.namaPekerjaan}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label
                          column
                          sm="4"
                          style={textRight}
                        ></Form.Label>
                        <Col sm="8">
                          <Form.Control
                            as="textarea"
                            rows={3}
                            value={ketPekerjaan}
                            disabled
                            readOnly
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Kecamatan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeKecamatan.kodeKecamatan} - ${kodeKecamatan.namaKecamatan}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Kode Leasing :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={`${kodeLeasing.kodeLeasing} - ${kodeLeasing.namaLeasing}`}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Email :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={email} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>

            {/* Data Motor */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                DATA MOTOR
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nopol :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={nopol} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Rangka :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noRangka} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Mesin :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noMesin} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nama Stnk :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaStnk} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tipe :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tipe} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Nama Warna :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={namaWarna} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tahun Perakitan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tahun} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          No. Bpkb :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={noBpkb} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>

            {/* Data Rincian Harga (Input) */}
            <Paper elevation={6} sx={mainContainer}>
              <Typography variant="h5" sx={titleStyle} color="primary">
                RINCIAN HARGA
              </Typography>
              <Box sx={showDataContainer}>
                <Box sx={showDataWrapper}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Tanggal Angsuran I :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tglAngFormatted} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Harga Tunai :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            //type="number"
                            value={hargaTunai.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Uang Muka :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            //type="number"
                            value={uangMuka.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Sisa Piutang :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={sisaPiutang.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
                <Box sx={[showDataWrapper, secondWrapper]}>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRightSmall}>
                          Tanggal Angsuran Akhir :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control value={tglAngAkhirFormatted} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Angsuran/Bulan :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            //type="number"
                            value={angPerBulan.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Tenor :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control type="number" value={tenor} disabled />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                  <Row>
                    <Col>
                      <Form.Group
                        as={Row}
                        className="mb-3"
                        controlId="formPlaintextPassword"
                      >
                        <Form.Label column sm="4" style={textRight}>
                          Total Piutang :
                        </Form.Label>
                        <Col sm="8">
                          <Form.Control
                            value={jumlahPiutang.toLocaleString("en-US")}
                            disabled
                          />
                        </Col>
                      </Form.Group>
                    </Col>
                  </Row>
                </Box>
              </Box>
            </Paper>
          </Box>
          <Divider sx={dividerStyle} />
        </>
      )}
      <Form onSubmit={searchData}>
        <Box sx={searchBarContainer}>
          <SearchBar value={query} setSearchTerm={setQuery} />
          <Button
            variant="contained"
            type="submit"
            color="primary"
            disableElevation
          >
            Cari
          </Button>
        </Box>
      </Form>
      <Box sx={tableContainer}>
        <ShowTableJualCustomerPerSurveyor currentPosts={jualsData} />
      </Box>
      <Box sx={tableContainer}>
        <Pagination
          count={pages}
          page={page + 1}
          onChange={handleChange}
          color="primary"
          size={screenSize <= 600 ? "small" : "large"}
        />
      </Box>
    </Box>
  );
};

export default TampilCustomerPerSurveyor;

const subTitleText = {
  fontWeight: "900",
};

const buttonModifierContainer = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const dividerStyle = {
  pt: 4,
};

const showDataContainer = {
  mt: 4,
  display: "flex",
  flexDirection: {
    xs: "column",
    sm: "row",
  },
};

const showDataWrapper = {
  display: "flex",
  flex: 1,
  flexDirection: "column",
  // maxWidth: {
  //   md: "40vw",
  // },
};

const searchBarContainer = {
  pt: 6,
  display: "flex",
  justifyContent: "center",
};

const tableContainer = {
  pt: 4,
  display: "flex",
  justifyContent: "center",
};

const labelInput = {
  fontWeight: "600",
  marginLeft: 1,
};

const spacingTop = {
  mt: 4,
};

const secondWrapper = {
  marginLeft: {
    sm: 4,
  },
  marginTop: {
    sm: 0,
    xs: 4,
  },
};

const downloadButtons = {
  mt: 4,
  display: "flex",
  flexWrap: "wrap",
  justifyContent: "center",
};

const mainContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: {
    xs: 4,
    md: 0,
  },
};

const pilihCetakContainer = {
  padding: 3,
  borderRadius: "20px",
  margin: {
    sm: 0,
    md: 4,
  },
  marginTop: 2,
};

const titleStyle = {
  textAlign: "center",
  fontWeight: "600",
};

const contentContainer = {
  p: {
    sm: 0,
    md: 3,
  },
  pt: {
    sm: 0,
    md: 1,
  },
  mt: {
    sm: 0,
    md: 2,
  },
};

const carouselStyle = {
  display: "flex",
  height: "200px",
};

const carouselCard = {
  m: "auto",
  mt: 2,
  maxWidth: "500px",
  maxHeight: "500px",
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  backgroundColor: "primary",
};

const oldImageCardMedia = {
  display: "flex",
  maxHeight: "150px",
};
